<template>
    <div class="bottom">
        <div class="center">
            <div class="foot_title">
                {{ Alldata.title }}
            </div>
            <div class="english">{{ Alldata.titleEn }} ({{ Alldata.titleAbbreviation }})</div>
            <div class="content">
                <div class="contetn_left">
                    <div class="left_title">
                        主办单位：
                    </div>
                    <div class="left_content">
                        <div class="logo_box">
                            <img :src=getImgView(conferenceSponsor.sponsorLogoUrl) alt="">
                        </div>
                        <div class="unit_name">
                            {{ conferenceSponsor.sponsorName }}<br />
                            {{ conferenceSponsor.sponsorNameEn }}
                        </div>
                    </div>

                </div>
                <div class="content_right" v-if="partList[0].state">
                    <div class="right_title">
                        联系方式：
                    </div>
                    <div class="right_content">
                        <div class="contact_item">注册参会&nbsp;&nbsp;&nbsp;{{
                            conferenceContactWay.contactWayAttendeeName }}
                            <div class="icon">
                                <img src="@/assets/email.png" alt="">
                            </div>
                            <div style="text-decoration: underline;">{{ conferenceContactWay.contactWayAttendeeEmail
                            }}</div>
                        </div>
                        <div class="contact_item">专题投稿&nbsp;&nbsp;&nbsp;{{
                            conferenceContactWay.contactWayContributeName }}
                            <div class="icon">
                                <img src="@/assets/email.png" alt="">
                            </div>
                            <div style="text-decoration: underline;">{{
                                conferenceContactWay.contactWayContributeEmail }}</div>
                        </div>
                        <div class="contact_item">会议赞助&nbsp;&nbsp;&nbsp;{{
                            conferenceContactWay.contactWaySponsorName }}
                            <div class="icon">
                                <img src="@/assets/email.png" alt="">
                            </div>
                            <div style="text-decoration: underline;">{{ conferenceContactWay.contactWaySponsorEmail
                            }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
        this.getDate()
    }
}
</script>

<style lang="less" scoped>
.bottom {
    background-color: #2B77AE;
    color: #ffffff;
    width: auto;
    // height: 100px;
    padding: 50px;

    .foot_title {
        font-size: 20px;
        font-weight: bold;
    }

    .english {
        margin-top: 5px;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .content {
        display: flex;
        align-items: top;

        .contetn_left {
            width: 50%;


            .left_title {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .left_content {
                display: flex;
                align-items: center;

                .logo_box {
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }
            }

        }

        .content_right {
            width: calc(50% - 20px);
            padding-left: 20px;
            border-left: 1px solid #ffffff;

            .right_title {
                font-size: 18px;
                margin-bottom: 10px;

            }

            .right_content {
                .contact_item {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 20px;
                        height: 20px;
                        overflow: hidden;
                        margin: 0 5px 0 10px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

        }
    }

}
</style>