<template>
  <div class="layout">
    <el-container>
      <el-header>
        <header-part></header-part>
      </el-header>
      <el-main>
        <div class="banner">
          <img :src=getImgView(banner) alt="">
        </div>
        <router-view />
      </el-main>
      <el-footer>
        <foot-part></foot-part>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
import headerPart from '@/components/headerPart.vue'
import footPart from '@/components/footPart.vue';
export default {
  name: 'Layout',
  components: {
    headerPart,
    footPart
  },
  data() {
    return {
      banner: '',
    }
  },
  mounted() {
    this.getDate();
  },
}
</script>
<style lang="less" scoped>
.layout {
  position: relative;
  padding-top: 60px;
  background-color: #ffffff;

  .el-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 100;
    background-color: #fff;
    color: #333;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .el-footer {
    padding: 0;
  }

  .el-main {
    padding: 0;
    min-height: calc(100vh - 60px);
    width: auto;
    .banner {
      width: 100%;
      height: 400px;
      // background: url(../assets/banner.jpg) center center no-repeat;
      // background-size: cover;
      border-bottom: 2px solid #2B77AE;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img{
        height: 100%;
      }
    }

    // height: 100vh;
  }
}
</style>