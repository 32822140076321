<template>
    <div class="header">
        <div class="center">
            <div class="logo">
                <img :src=getImgView(logo) alt="">
            </div>
            <div class="navList">
                <router-link class="navLink" v-for="(item, index) in navList" :key="index" @click.native="selNav(item.url)"
                    :class="{ select: item.url == path }" :to="item.url">
                    {{ item.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

            path: '',//选中项的地址，

            logo: '',//网站logo
            // 接口获取显示的导航栏的数据

        };
    },
    mounted() {
        this.path = window.location.pathname;
        let item = this.navList.find(obj => obj.url == this.path);
        if (item == false) {
            this.path = '/home'
        }
        this.getDate()
    },
    methods: {
        selNav(path) {
            this.path = path;
        },

    }
}
</script>

<style lang="less" scoped>
.header {
    height: 60px;

    .center {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            height: 60px;

            img {
                height: 100%;
            }
        }

        .navList {
            display: flex;

            .navLink {
                border-radius: 3px;
                margin: 0 5px;
                cursor: pointer;
                padding: 10px 20px;
                display: block;
            }

            .navLink:hover {
                background-color: #2B77AE;
                color: #ffffff;
            }

            .select {
                color: #ffffff;
                background-color: #2B77AE;
            }
        }
    }
}
</style>